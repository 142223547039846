import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';

const About = () => (
    <Layout>
        <Helmet>
            <title>Studio Three &#187; About Us</title>
            <meta name="description" content="Studio Three is a hair salon in Phoenix, Arizona specializing in hair cuts, hair color, and blonde hair balayages. Victoria Adams, and Makenna Billingsley are two trendy hair stylists." />
            <link rel="canonical" href={`https://studiothreephx.com/about/`} />
        </Helmet>
        <main className="main main-about2 jarallax" data-jarallax='{"speed": 0.6}'>
            <div className="container-fluid">
                <div className="opener">
                    <div className="col-md-8 col-md-offset-2">
                        <h1>About <i>us</i></h1>
                        <p className="lead">A few words about Studio Three</p>
                        <ul className="social-list">
                            <li><a href="https://instagram.com/studiothreephx" className="fa fa-instagram"><span className="d-none"></span></a></li>
                            <li><a href="https://www.facebook.com/Studio-Three-107084114439318" className="fa fa-facebook"><span className="d-none"></span></a></li>
                            <li><a href="https://yelp.com/biz/studio-three-phoenix" className="fa fa-yelp"><span className="d-none"></span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </main>

        <div className="content">
            <section className="section">
                <div className="container">
                    <div className="row">
                        <header className="text-center col-md-10 col-md-offset-1">
                            <h2 className="section-title-2">Our Story</h2>
                            <div className="about-entry text-center">
                                <p>Studio Three is a hair salon created by two, young and passionate hair stylists in Phoenix, AZ - Victoria Adams and Makenna Billingsley. They offer Cut, Color, and Blonding services. Having worked in the same salon together for many years, the two hairdressers decided to step up their game and open a salon. Putting their minds together, they have created a comfortable and uplifting atmosphere for their clients.</p>
                                <p>From platinum blonde hair color to shoulder length hairstyles, the two stylists are always ready to make their customers look and feel beautiful. Representing the younger generation of cosmetologists, they have been trained to use the latest techniques and styles that are growing throughout the industry. By creating Studio Three, Victoria and Makenna are able to share their talents and help more people look and feel amazing.</p>
                            </div>
                        </header>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
);

export default About;