import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import stylists from '../data/stylists.json';
import { Helmet } from 'react-helmet';

const Stylists = () => (
    <Layout isLight={true}>
        <Helmet>
            <title>Studio Three &#187; Stylists</title>
            <meta name="description" content="Studio Three was created by two, modern hair stylists: Victoria Adams and Makenna Schmit. They specialize in trendy haircuts and colors especially blondes." />
            <link rel="canonical" href="https://studiothreephx.com/stylists/" />
        </Helmet>
        <div className="content navbar-space">
            <section id="portfolio">
                <section className="section pb-0">
                    <div className="row">
                        <div className="container">
                            <header className="text-center col-md-8 col-md-offset-2">
                                <h2 className="section-title">Meet the  <i className="playfair">Team</i></h2>
                                <p>Meet the three stylists that created Studio Three. You can click on their pictures to learn more and schedule an appointment!</p>
                            </header>
                        </div>
                    </div>
                    <div className="isotope">
                        {stylists.map((stylist) => (
                            <div key={stylist.id} className="isotope-item">
                                <Link to={`/stylists/${stylist.id}`} title="Stationary Design">
                                    <figure className="showcase-item">
                                        <div className="showcase-item-thumbnail"><img alt="" src={stylist.image} /></div>
                                        <figcaption className="showcase-item-hover">
                                            <div className="showcase-item-info">
                                                <div className="showcase-item-category">Hair Stylist</div>
                                                <div className="showcase-item-title">{stylist.name}</div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </Link>
                            </div>
                        ))}
                    </div>
                </section>
            </section>
        </div>
    </Layout>
);

export default Stylists;