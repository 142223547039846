import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Contact = () => (
    <Layout isLight={true}>
        <Helmet>
            <title>Studio Three &#187; Contact</title>
            <meta name="description" content="Get in touch with one of our stylists at Studio Three" />
            <link rel="canonical" href={`https://studiothreephx.com/contact/`} />
        </Helmet>
        <div className="content navbar-space">
            <section id="contact" className="contacts">
                <div id="map" className="map">
                    <iframe width="100%" height="100%" frameBorder="0" style={{ border: 0 }} src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ_3zftHINK4cRh3vfbys1Pyk&key=AIzaSyAXjVdu-saDIbLdU7aSzG16r-7b0qkqDX4" allowfullscreen></iframe>
                </div>
                <section className="section">
                    <div className="container">
                        <div className="row">
                            <h2>Contact Us</h2>
                            <p>Use the button below to navigate to the stylists page. There, you can choose a stylist and contact them about a specific question or schedule an appointment.</p>
                            <Link className="btn" to="/stylists">Stylists</Link>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    </Layout>
);

export default Contact;