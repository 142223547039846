import React from 'react';
import images from "../data/gallery.json";


class ImageGallery extends React.Component {
    render = () => (
        <section>
            <div className="clearfix">
                {images.map((image, index) => (
                    <div key={index} className="col-portfolio col-sm-6 col-lg-4">
                        <a href={image.anchorUrl} target="_blank">
                            <figure className="showcase-item">
                                <div className="showcase-item-thumbnail"><img alt={image.caption} src={image.source} /></div>
                                <figcaption className="showcase-item-hover">
                                    <div className="showcase-item-info">
                                        <div className="showcase-item-category">instagram</div>
                                        <div className="showcase-item-title">{image.stylist}</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </a>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default ImageGallery;