import React from 'react';
import { Link } from 'react-router-dom';
import stylists from '../data/stylists.json';

Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    writable: true,
    configurable: true
});

const Layout = ({ children, isLight }) => (
    <>
        <header className={`navbar navbar-desctop hidden-xs hidden-sm ${isLight ? 'light' : ''}`}>
            <div>
                <Link to="#" className="brand js-target-scroll">
                    <img
                        className="img-fluid"
                        src={isLight ? `/img/logo.png` : `/img/logo-white.png`}
                        alt="Studio Three is a hair salon in Phoenix, AZ"
                        width="45px"
                    />
                </Link>

                <ul className="social-list">
                    <li><a href="https://instagram.com/studiothreephx" className="fa fa-instagram"><span className="d-none"></span></a></li>
                    <li><a href="https://www.facebook.com/Studio-Three-107084114439318" className="fa fa-facebook"><span className="d-none"></span></a></li>
                    <li><a href="https://yelp.com/biz/studio-three-phoenix" className="fa fa-yelp"><span className="d-none"></span></a></li>
                </ul>

                <nav className="nav-desctop">
                    <ul className="nav-desctop-list">
                        <li className="menu-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/about">About</Link>
                        </li>
                        <li className="menu-item-has-children">
                            <Link to="/stylists">Stylists</Link>
                            <ul>
                                {stylists.map((stylist) => (
                                    <li>
                                        <Link to={`/stylists/${stylist.id}`}>
                                            {stylist.id.capitalize()}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li className="menu-item">
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>

        <header className="navbar navbar-mobile md-hide light">
            <Link to="#" className="brand js-target-scroll">
                <img
                    className="img-fluid"
                    src="/img/logo.png"
                    alt="Studio Three is a hair salon in Phoenix, AZ"
                    width="45px"
                />
            </Link>


            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#nav-collapse">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
            </button>

            <ul className="social-list">
                <li><a href="https://instagram.com/studiothreephx" className="fa fa-instagram"><span className="d-none"></span></a></li>
                <li><a href="https://www.facebook.com/Studio-Three-107084114439318" className="fa fa-facebook"><span className="d-none"></span></a></li>
                <li><a href="https://yelp.com/biz/studio-three-phoenix" className="fa fa-yelp"><span className="d-none"></span></a></li>
            </ul>

            <nav className="nav-mobile">
                <div className="collapse navbar-collapse" id="nav-collapse">
                    <ul className="nav-mobile-list">
                        <li className="menu-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/about">About</Link>
                        </li>
                        <li className="menu-item-has-children">
                            <Link to="/stylists">Stylists</Link>
                            <ul>
                                <li><Link to="/stylists/victoria">Victoria</Link></li>
                                <li><Link to="/stylists/makenna">Makenna</Link></li>
                            </ul>
                        </li>
                        <li className="menu-item">
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>

        {children}

        <footer id="footer" className="footer">
            <div className="container">
                <div className="row-base row">
                    <div className="brand-info col-base col-md-6">
                        <Link to="#top" className="brand js-target-scroll">
                            Studio <span>Three</span>
                        </Link>
                        <p>Studio Three is a hair salon in Phoenix, AZ that provides cut and color services.</p>
                    </div>
                    <div className="col-base col-md-3 col-md-offset-3">
                        <ul className="social-list">
                            <li><a href="https://instagram.com/studiothreephx" className="fa fa-instagram"><span className="d-none"></span></a></li>
                            <li><a href="https://facebook.com" className="fa fa-facebook"><span className="d-none"></span></a></li>
                            <li><a href="https://yelp.com/biz/studio-three-phoenix" className="fa fa-yelp"><span className="d-none"></span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="copyrights">
                        &copy; Copyright {(new Date()).getFullYear()} Studio Three All Rights Reserved | Created by <a href="https://dylate.net/">Dylate</a>
                    </div>
                </div>
            </div>
        </footer>
    </>
);

export default Layout;