import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Stylists from './pages/Stylists';
import Stylist from './pages/Stylist';
import About from './pages/About';
import Contact from './pages/Contact';

const App = () => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route path="/stylists/:id" component={Stylist} />
    <Route path="/stylists">
      <Stylists />
    </Route>
    <Route path="/about">
      <About />
    </Route>
    <Route path="/contact">
      <Contact />
    </Route>
  </Switch>
);

export default App;
