import React from 'react';
import Layout from '../components/Layout';
import stylists from '../data/stylists.json';
import { Helmet } from 'react-helmet';

const Stylist = ({ match }) => {
    const { id } = match.params;
    const stylist = stylists.filter((item) => item.id === id)[0];
    console.log(stylist);
    return (
        <Layout isLight={true}>
            <Helmet>
                <title>Studio Three &#187; Stylist &#187; {stylist.name}</title>
                <meta name="description" content={stylist.bio} />
                <link rel="canonical" href={`https://studiothreephx.com/stylists/${stylist.id}/`} />
            </Helmet>
            <div className="content navbar-space">
                <section className="section-sm pb-0">
                    <div className="container">
                        <div className="section-md">
                            <div className="row">
                                <header className="container-left-md-fluid col-md-6 text-center">
                                    <h2 className="section-title">{stylist.name}</h2>
                                    <ul className="social-list">
                                        {stylist.social.map((social => (
                                            <li>
                                                <a href={social.link} target="_blank" className={`fa fa-${social.network}`}></a>
                                            </li>
                                        )))}
                                    </ul>
                                    <p dangerouslySetInnerHTML={{ __html: stylist.bio }}/>
                                    <a className="btn btn-primary" target="_blank" href={stylist.bookingLink}>Book Appointment</a>
                                </header>
                                <div className="bg-right-md-fluid col-md-6" style={{
                                    backgroundImage: `url(${stylist.image})`
                                }}></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export default Stylist;